<template id="top">
  <div class="has-text-weight-bold">
    <div class="hero">
      <div class="hero-body">
        <!-- PC と SP でコードを分割します。分割前は reverse-columns で SP のときに画像を最初に表示していましたが CLS が改善できなかった -->
        <!-- PC -->
        <div v-if="!$isMobile()" class="columns has-text-black is-vcentered reverse-columns">
          <div class="column is-4 margin-bottom-30px has-text-left-desktop is-offset-2">
            <div class="hero-title">
              <h1 class="hero-title-text" v-html="$t('top.hero')"></h1>
              <div class="hover-shimmer"></div>
            </div>
            <div class="margin-top-18px margin-bottom-30px">
              <p>{{ $t('top.catch') }}</p>
            </div>
            <div>
              <ButtonLogin />
              <ButtonSignUp />
              <ButtonDashboard />
            </div>
          </div>
          <div class="column is-6 padding-right">
            <picture>
              <source srcset="/static/images/logo-256x256.avif" media="(max-width: 640px)" type="image/avif">
              <source srcset="/static/images/logo-512x512.avif" media="(min-width: 641px)" type="image/avif">
              <img src="/static/images/logo-512x512.avif" loading="lazy" alt="HStorage ロゴ" width="512" height="512">
            </picture>
          </div>
        </div>
        <!-- SP -->
        <div v-else class="has-text-black">
          <img srcset="/static/images/logo-256x256.avif 256w, /static/images/logo-512x512.avif 512w"
            sizes="(max-width: 640px) 256px, 512px" src="/static/images/logo-512x512.avif" loading="lazy"
            alt="HStorage ロゴ" width="512" height="512" />
          <div class="margin-bottom-30px">
            <h1 class="hero-title-text" v-html="$t('top.hero')"></h1>
            <div class="margin-top-18px margin-bottom-30px">
              <p>{{ $t('top.catch') }}</p>
            </div>
            <div>
              <ButtonSignUp />
              <!-- ログインしたあとは ButtonDashboardBottom が表示される -->
              <ButtonDashboard v-if="!$store.state.isLoggedIn" class="my-0 mx-auto" />
            </div>
          </div>
        </div>
        <!-- guest upload -->
        <div v-if="!$store.state.isLoggedIn">
          <b-button class="button is-primary is-focused has-text-weight-bold" icon-pack="fas" icon-right="rocket"
            rounded @click="openModal = true">お試しアップロード</b-button>
          <b-modal v-model="openModal" aria-role="dialog" aria-label="HStorage ゲストアップロード" aria-modal :can-cancel="true">
            <template #default="props">
              <div @close="props.close" class="my-modal">
                <h3>
                  <span class="section-title">＼ お試しアップロード ／</span>
                </h3>
                <div>
                  <p class="margin-bottom-12px">下記の制限がありますがユーザー登録（無料）せずに HStorage をお試しいただけます！</p>
                  <p>■ 最大 100GB までのアップロード</p>
                  <p>■ ファイルの保存期間は7日間まで</p>
                </div>
                <UploadComponent :is-guest="true" />
              </div>
            </template>
          </b-modal>
        </div>
      </div>
    </div>

    <section class="section">
      <p>＼ 45秒 CM動画をみる ／</p>
      <a href="https://www.youtube.com/watch?v=cv5kKIzZs3U" rel="noreferrer" target="_blank">
        <picture>
          <source media="(max-width:520px)" srcset="/static/images/cm.webp 1920w" sizes="10vw">
          <source srcset="/static/images/cm.webp 1920w" sizes="(max-width:1280px) 50vw">
          <img class="cm" src="/static/images/cm.webp" draggable="false" width="640" height="360"
            alt="クラウドストレージ HStorage CM動画">
        </picture>
      </a>
    </section>

    <div class="margin-top-30px margin-bottom-30px">
      <p>{{ $t('top.count.1') }} <br class="is-hidden-desktop"><span class="count">
          <count-up :startVal="0" :endVal="this.$store.state.init['data_usage_info'].count" :delay="count.delay"
            :options="count.options" @ready="countOnReady" />
        </span>{{ $t('top.count.2') }}
      </p>
    </div>

    <AppLinks :isCenter="true" :isDark="false"></AppLinks>

    <div class="margin-top-30px">
      <a href="https://www.itreview.jp/products/hstorage/profile#scroll" rel="noreferer" target="_blank">
        <picture>
          <source srcset="/static/images/itreview-half.webp" media="(max-width: 640px)" type="image/webp">
          <img src="/static/images/itreview.png" loading="lazy" alt="ITReview ロゴ" width="135">
        </picture>
      </a>
    </div>

    <div class="margin-top-30px margin-bottom-30px">
      <div class="has-text-grey has-text-normal">
        <marquee-text :repeat="5" :duration="60">
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.lycorp.co.jp/ja/" rel="noreferer"
              target="_blank">LINEヤフー株式会社</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://meldia.co.jp/" rel="noreferer" target="_blank">株式会社メルディア
              三栄建築設計</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.osakac.ac.jp/corp/" rel="noreferer" target="_blank">学校法人
              大阪電気通信大学</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.a-too.co.jp/ja" rel="noreferer" target="_blank">株式会社 エーツー
              駿河屋</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.hot-cha.tv/" rel="noreferer"
              target="_blank">長門市ケーブルテレビ</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://jtrustc.co.jp/" rel="noreferer" target="_blank">株式会社
              インターネットプライバシー研究所</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.kk-daiwakagaku.co.jp/" rel="noreferer" target="_blank">株式会社
              ダイワ化学</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://shizuoka.kufu.company/" rel="noreferer" target="_blank">株式会社
              しずおかオンライン</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://sig-co.com/" rel="noreferer" target="_blank">株式会社 SIG</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://adnest.co.jp/" rel="noreferer" target="_blank">株式会社
              アドネスト</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.mynet.co.jp/" rel="noreferer" target="_blank">株式会社
              マイネット</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.imagicalab.co.jp/" rel="noreferer" target="_blank">株式会社
              IMAGICA
              Lab.</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.tohoku.ac.jp/japanese/" rel="noreferer" target="_blank">国立大学法人
              東北大学</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.chiba-u.ac.jp/" rel="noreferer" target="_blank">国立大学法人
              千葉大学</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.hokudai.ac.jp/" rel="noreferer" target="_blank">国立大学法人
              北海道大学</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.ikuei.ed.jp/" rel="noreferer" target="_blank">学校法人
              奈良育英学園</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.n-fukushi.ac.jp/" rel="noreferer" target="_blank">学校法人
              日本福祉大学</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.heisei-iryo.ac.jp/" rel="noreferer" target="_blank">学校法人
              平成医療学園</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://www.jages.net/HOME/" rel="noreferer" target="_blank">一般社団法人
              日本老年学的評価機構</a></span>
          <span class="marquee-text-margin">
            <a class="has-text-normal" href="https://toshimamura-csw.main.jp/" rel="noreferer" target="_blank">社会福祉法人
              利島村社会福祉協議会</a></span>
        </marquee-text>
      </div>
      <div>
        <p class="has-text-grey" style="font-size:12px">他60社以上 2024/08/0 更新</p>
      </div>
    </div>

    <section class="section" id="tokutyou">
      <h2 class="margin-bottom-30px">
        <span class="section-title">Service</span>
        <span class="section-description" v-html="$t('top.tokutyou.title')" />
      </h2>
      <div class="margin-bottom-30px">
        <img srcset="/static/images/header-320.avif 320w,
             /static/images/header-640.avif 640w,
             /static/images/header-1024.avif 1024w,
             /static/images/header-1280.avif 1280w" sizes="(max-width: 320px) 320px,
            (max-width: 640px) 640px,
            (max-width: 1024px) 1024px,
            1280px" src="https://hstorage.io/static/images/header-1280.avif" alt="HStorage ヘッダー画像" width="1280"
          height="1280">
      </div>
      <p class="margin-bottom-60px" v-html="$t('top.tokutyou.description')" />
      <div class="margin-bottom-60px">
        <p class="rainbow tokutyou-number">200,000+</p>
        <p class="margin-bottom-6px">月間訪問者数</p>
        <p>
          HStorageはファイルのアップロード・管理・ダウンロード・動画の再生のため、世界から月20万人以上に訪問されています。</p>
      </div>
      <div class="margin-bottom-60px">
        <p class="rainbow tokutyou-number">35,000,000+ <span>20TB+</span></p>
        <p class="margin-bottom-6px">月間ファイル送信数</p>
        <p>HStorageはクラウドストレージとして月に3,500万回以上のファイルを送信し、その容量は20TBを超えています。</p>
      </div>
      <div class="margin-bottom-60px">
        <p class="rainbow tokutyou-number">0</p>
        <p class="margin-bottom-6px">セキュリティ事故</p>
        <p>HStorageのセキュリティ事故は未だゼロです。クラウドストレージではセキュリティがもっとも大事です。</p>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-parent is-12">
          <div class="tile is-child box">
            <div class="columns">
              <div class="column has-text-left card-padding-mobile card-padding">
                <h3 class="title" v-html="$t('top.card.security.title')" />
                <p v-html="$t('top.card.security.description')" style="margin-bottom:1.5rem" />
                <router-link to="/about/security" class="has-text-primary">
                  <p>{{ $t('top.card.security.link') }}
                    <img src="/static/images/icons/chevron-right-solid.svg" draggable="false" alt="arrow right"
                      width="8px" height="12.8">
                  </p>
                </router-link>
              </div>
              <div class="column security-background" v-lazy:background-image="bg_image_security" />
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child box card-padding">
            <h3 class="title" v-html="$t('top.card.free.title')"></h3>
            <p v-html="$t('top.card.free.description')"></p>
            <br class="is-hidden-mobile">
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child box card-padding card-padding">
            <h3 class="title" v-html="$t('top.card.direct.title')"></h3>
            <p v-html="$t('top.card.direct.description')"></p>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child box card-padding card-padding">
            <h3 class="title" v-html="$t('top.card.network.title')"></h3>
            <p v-html="$t('top.card.network.description')"></p>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-parent is-12">
          <div class="tile is-child box">
            <div class="columns">
              <div class="column has-text-left card-padding-mobile card-padding">
                <h3 class="title" v-html="$t('top.card.dashboard.title')" />
                <p v-html="$t('top.card.dashboard.description')" style="margin-bottom:1.5rem" />
                <router-link to="/about/dashboard" class="has-text-primary">
                  <p>{{ $t('top.card.dashboard.link') }}
                    <img src="/static/images/icons/chevron-right-solid.svg" draggable="false" alt="arrow right"
                      width="8px" height="12.8">
                  </p>
                </router-link>
              </div>
              <div class="column dashboard-background" v-lazy:background-image="bg_image_dashboard"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section" id="features">
      <h2 class="margin-bottom-30px">
        <span class="section-title">Features</span>
        <span class="section-description" v-html="$t('top.features.title')" />
      </h2>
      <p class="margin-bottom-60px" v-html="$t('top.features.description')" />

      <div class="tile is-ancestor">
        <div class="tile is-3 is-vertical is-parent">
          <div class="tile is-child is-child-center box tile-card-100gb">
            <p class="tokutyou-number" v-html="$t('top.card.upload')"></p>
          </div>
          <div class="tile is-child is-child-center box tile-card-direct">
            <p class="rainbow" style="font-size:2.6em">FTPクライアント対応</p>
          </div>
        </div>
        <div class="tile is-vertical is-parent">
          <div class="tile is-child box">
            <p class="title">{{ $t('top.card.download.download') }}</p>
            <div class="columns">
              <div class="column">
                <p>直リンク</p>
                <div class="top-card-icon">
                  <font-awesome-icon :icon="['fas', 'link']" size="4x" />
                </div>
              </div>
              <div class="column">
                <p>{{ $t('top.card.download.count') }}</p>
                <div class="top-card-icon">
                  <font-awesome-icon :icon="['fas', 'arrow-down-wide-short']" size="4x" />
                </div>
              </div>
              <div class="column">
                <p>{{ $t('top.card.download.limit_date') }}</p>
                <div class="top-card-icon">
                  <font-awesome-icon :icon="['fas', 'calendar-days']" size="4x" />
                </div>
              </div>
              <div class="column">
                <p>{{ $t('top.card.download.password') }}</p>
                <div class="top-card-icon">
                  <font-awesome-icon :icon="['fas', 'key']" size="4x" />
                </div>
              </div>
              <div class="column">
                <p>{{ $t('top.card.download.encrypt') }}</p>
                <div class="top-card-icon">
                  <font-awesome-icon :icon="['fas', 'microchip']" size="4x" />
                </div>
              </div>
            </div>
          </div>
          <div class="tile is-child box">
            <img src="/static/images/top-card-ogp.avif" width="640" height="336" loading="lazy" alt="TOPページ ヘッダー画像">
          </div>
        </div>
        <div class="tile is-3 is-vertical is-parent">
          <div class="tile is-child is-child-center box tile-card-infinite">
            <p style="font-size:3em">{{ $t('top.card.infinite.storage') }}</p>
          </div>
          <div class="tile is-child is-child-center box tile-card-infinite">
            <p style="font-size:3em">{{ $t('top.card.infinite.count') }}</p>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">{{ $t('top.card.processing') }}</p>
            <font-awesome-icon :icon="['fas', 'microchip']" size="5x" />
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">{{ $t('top.card.group') }}</p>
            <font-awesome-icon :icon="['fas', 'layer-group']" size="4x" />
          </article>
        </div>
      </div>

      <div class="tile is-ancestor has-text-white">
        <div class="tile is-parent">
          <div class="tile is-child box tile-card-security">
            <p class="title has-text-white">{{ $t('top.card.tile.security.title') }}</p>
            <div class="columns">
              <div class="column">
                <p>{{ $t('top.card.tile.security.encrypt.title') }}</p>
                <div class="top-card-icon">
                  <font-awesome-icon :icon="['fas', 'microchip']" size="4x" />
                </div>
                <p v-html="$t('top.card.tile.security.encrypt.desc')"></p>
              </div>
              <div class="column">
                <p>{{ $t('top.card.tile.security.virus.title') }}</p>
                <div class="top-card-icon">
                  <font-awesome-icon :icon="['fas', 'shield']" size="4x" />
                </div>
                <p v-html="$t('top.card.tile.security.virus.desc')"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section" id="blog">
      <h2 class="margin-bottom-30px">
        <span class="section-title">Update</span>
        <span class="section-description" v-html="$t('top.update.title')" />
      </h2>
      <p class="margin-bottom-60px" v-html="$t('top.update.description')" />

      <div class="tile is-ancestor">
        <div class="tile is-parent" v-for="post in rss.posts" :key="post.index">
          <div class="tile is-child box card-padding">
            <a :href="post.url + '?utm_source=hstorage'" rel="noreferrer">
              <img :src="post.cover" class="image" style="margin: 0 auto;width: 256px;height: 180px;border-radius:30px"
                loading="lazy" alt="HStorage ブログ サムネイル" />
              <p class="has-text-normal margin-top-12px">
                <strong>{{ post.title }}</strong>
                <br>
                <small class="has-text-grey">{{ post.date }}</small>
              </p>
            </a>
          </div>
        </div>
      </div>
    </section>

    <!-- https://github.com/FortAwesome/Font-Awesome/issues/11925#issuecomment-393975346 -->
    <svg width="0" height="0">
      <radialGradient id="lgrad" cx="50%" cy="50%" r="50%">
        <stop offset="0%" style="stop-color:rgb(238,53,115);stop-opacity:1.00" />
        <stop offset="50%" style="stop-color:rgb(73,116,187);stop-opacity:1.00" />
        <stop offset="100%" style="stop-color:rgb(249,182,77);stop-opacity:1.00" />
      </radialGradient>
    </svg>
  </div>
</template>

<script>
import appConfig from "../app.config.json";
import { useHead } from '@unhead/vue'
import { defineAsyncComponent } from "vue";

import CountUp from 'vue-countup-v3'
import ButtonSignUp from "../components/ButtonSignUp.vue";
import ButtonDashboard from "../components/ButtonDashboard.vue";
import ButtonLogin from "../components/ButtonLogin.vue";
import AppLinks from "../components/AppLinks.vue";

document.addEventListener("DOMContentLoaded", function () {
  var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));

  if ("IntersectionObserver" in window) {
    let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });

    lazyBackgrounds.forEach(function (lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }
});

export default {
  name: "TOP",
  components: {
    UploadComponent: defineAsyncComponent(() => import('../components/dashboard/UploadComponent.vue')),
    AppLinks,
    CountUp,
    ButtonLogin, ButtonDashboard, ButtonSignUp
  },
  data() {
    return {
      openModal: false,
      bg_image_dashboard: '/static/images/card_dashboard.webp',
      bg_image_security: '/static/images/card_security.webp',
      rss: {
        feedUrl: "https://hstorage.io/blog/feed.json",
        posts: [],
      },
      count: {
        delay: 2000,
        options: {
          useEasing: true,
          useGrouping: true,
          duration: 3,
          separator: ',',
          decimal: '.',
          prefix: '',
          suffix: ''
        }
      },
    }
  },
  async created() {
    useHead({
      title: `${this.$t('top.top')} | ${appConfig[this.$i18n.locale].title}`,
      meta: [{ name: 'description', content: appConfig[this.$i18n.locale].description.top }],
      link: [
        { rel: 'canonical', href: 'https://hstorage.io' },
      ]
    })

    const login = this.$route.query.login
    if (login) {
      await this.WaitingAuth0()
    }

    if (this.$route.query.is_deleted === 'true') {
      this.toastInfo(this.$t('top.deleted'))
    }

    this.$axios.get(this.rss.feedUrl).then(resp => {
      const items = resp.data.items

      items.forEach((item, index) => {
        // 3件だけ表示する
        if (index >= 3) {
          return
        }

        const dateStr = resp.data.items[0]['date_published']
        const date = new Date(dateStr);
        const yyyy = date.getFullYear()
        const mm = date.getMonth()
        const dd = date.getDate()

        this.rss.posts.push({
          index: index,
          title: item.title,
          url: item.url,
          cover: item.cover,
          date: `${yyyy}/${mm}/${dd}`
        })
      })
    })
  },
  methods: {
    countOnReady: function (instance, CountUp) {
      instance.update(this.$store.state.init['data_usage_info'].count);
    },
  }
}
</script>

<style lang="scss" scoped>
.hero-body {
  padding-top: unset;
  background: linear-gradient(135deg, rgba(250, 251, 252, 0.95) 0%, rgba(245, 247, 250, 0.95) 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at 10% 10%, rgba(73, 116, 187, 0.05) 0%, transparent 15%),
      radial-gradient(circle at 90% 90%, rgba(238, 53, 115, 0.05) 0%, transparent 15%);
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(45deg,
        transparent,
        transparent 10px,
        rgba(255, 255, 255, 0.1) 10px,
        rgba(255, 255, 255, 0.1) 20px);
    z-index: 1;
    pointer-events: none;
  }
}

.hero-title-text {
  font-size: 2.8em;
  font-weight: 900;
  position: relative;
}

@media screen and (max-width: 768px) {
  .hero-title-text {
    font-size: 2.6em;
  }

  .marquee-text-wrap {
    font-size: 12px;
  }

  .security-background {
    height: 300px
  }

  .dashboard-background {
    height: 300px
  }

  .title {
    font-size: 1.8rem
  }
}

@media screen and (min-width: 769px) {
  .marquee-text-wrap {
    font-size: 16px;
  }
}

@media screen and (min-width: 1920px) {}

@media screen and (min-width: 1921px) {
  .hero-body {
    padding-right: 20rem;
    padding-left: 20rem;
  }

  #tokutyou {
    padding: 5rem 50rem;
  }
}

@media screen and (min-width: 2559px) {
  .hero-body {
    padding-right: 40rem;
    padding-left: 40rem;
  }
}

@media screen and (min-width: 2561px) {}

.hero-body {
  padding-top: unset;
}

.marquee-text-margin {
  margin-right: 60px
}

.section {
  background-color: rgba(250, 251, 252, 1);
}

// https://angrytools.com/gradient/?0_EE3573,50_4974BB,100_F9B64D&r_90_50_50
svg.rainbow * {
  fill: url(#lgrad);
}

.tokutyou-number {
  font-size: 2.3rem;
}

.security-background {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.dashboard-background {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

h2 {
  font-weight: 500 !important;
  color: var(--default-font-color);
  line-height: 2rem;
}

div.count {
  margin: 60px auto 0;
  font-weight: bold;
  border: solid 3px #000000;
}

span.count {
  font-weight: bolder;
  font-size: x-large;
}

.content>p {
  color: white;
}

.media-content>p {
  font-size: 24px;
  color: white;
}

hr {
  height: 5px;
  border: none;
  width: 30px;
  text-align: center;
  display: inline-block;
  margin-top: 6px;
}

span.count {
  font-weight: bolder;
  font-size: x-large;
}

img.cm {
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}

.top-card-icon {
  margin: 12px 0;
}

.tile-card-direct {
  background-image: url(/static/images/top-card-direct.svg);
}

.tile-card-100gb {
  background-image: url(/static/images/top-card-100gb.svg);
  color: white;
}

.tile-card-infinite {
  background-image: url(/static/images/top-card-infinite.svg);
  color: white;
}

.tile-card-security {
  background-image: url(/static/images/top-card-security.svg);
  color: white;
}

.is-child-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-aspect {
  aspect-ratio: auto;
}
</style>
